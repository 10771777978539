import "$styles/index.css"
import "$styles/syntax-highlighting.css"

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"
import { themeChange } from 'theme-change'
import "bridgetown-quick-search/dist"
console.info("Bridgetown is loaded!")

themeChange()


document.addEventListener('DOMContentLoaded', function () {
    const themeCheckbox = document.querySelector('.theme-controller');

    // Initialize the checkbox state from localStorage
    const isDark = JSON.parse(localStorage.getItem('isdark')) || false;
    themeCheckbox.checked = isDark;
    updateTheme(isDark);

    // Event listener for checkbox changes
    themeCheckbox.addEventListener('change', function () {
        const isChecked = themeCheckbox.checked;
        localStorage.setItem('isdark', JSON.stringify(isChecked));
        updateTheme(isChecked);
    });
});

function updateTheme(isDark) {
    if (isDark) {
        document.body.classList.add('dark-theme'); // Add class for dark theme
    } else {
        document.body.classList.remove('dark-theme'); // Remove class for dark theme
    }
    // Additional theme-related changes can be added here
}